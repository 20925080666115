<template>
  <div class="userInfoPage">
    <Header />
    <Nav :route="navList" />
    <div class="content">
        <router-view></router-view>
    </div>
    
  </div>
</template>
<script>
import Header from "@/components/header";
import Nav from "@/components/nav";
export default {
  name: "study",
  components: {
    Header,
    Nav
  },
  data() {
    return {
        navList:[{
            title:'个人资料',
            name:'UserInfoIndex',
        },{
            title:'账号设置',
            name:'UserInfoSetting',
           
        },],
    };
  },
};
</script>
<style lang="scss">
@import "index.scss";
</style>